import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class DirectorsOfficersLiabilityInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Directors & Officers Liability (D&O) Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Whether your business in Louisiana is public, private, or a non-profit, officers and directors of the company must make difficult decisions every day for your company. Directors & Officers (D&O) Liability Insurance helps covers these members and your company itself." }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Business Executives Meeting"/>
        <Callout
          title="Directors & Officers Liability (D&O) Insurance"
          content="Whether your business in Louisiana is public, private or a non-profit, officers and directors of the company must make difficult decisions every day for your company. For some risky decisions, this can end up effecting them personally or affect your company’s financial situation. Directors & Officers (D&O) Liability Insurance helps cover these members and your company itself."
        />
        <div className="site-body page-content">
          <h2>What Directors & Officers Liability (D&O) Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Fiduciary Duty Claims.</strong> This is when a board misused or mismanaged company funds.</li>
          <li><strong>Employee Claims.</strong> For when a disgruntled employee claims wrongful termination, breach of contract, discrimination or invasion of privacy.</li>
          <li><strong>Competitor Claims.</strong> For when a competitor or organization claims defamation, trademark infringement or unfair competition.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="Since 2007, there has been more than 1,500 D&O claims each year, with 60% of them affecting non-profits."
            source="Risk Management Society (RIMS)"
          />
          <h2>What Directors & Officers Liability (D&O) Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li><strong>Criminal Conduct.</strong> Insurance providers are prohibited by law from covering criminal or fraudulent conduct since it would undermine the fundamental principles of law.</li>
          <li><strong>Owners.</strong> Many policies exclude members that own more than 10-15% of the company.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a D&O Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default DirectorsOfficersLiabilityInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "directors-officers-liability-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-8.jpg" }) {
      ...mainBg
    }
  }
`;
